.slide-enter {
  transform: translateY(40%);
  opacity: 0;
}

.slide-enter.slide-enter-active {
  opacity: 1;  
  transform: translateY(0);
  transition: all 0.2s ease-in;
}

.slide-exit {
  opacity: 1;
}

.slide-exit.slide-exit-active {
  opacity: 0;
  transform: translateX(150%);
  /* transition: all 0.5s ease-in; */
  transition: all 0.5s cubic-bezier(0.9,-0.4,0.3,1);
}