@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

html,
body,
#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.05em;
  background: linear-gradient(0deg, #2d3142, #212742);
}

button {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.05em;
}

.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}
