@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
html,
body,
#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.05em;
  background: -webkit-linear-gradient(bottom, #2d3142, #212742);
  background: linear-gradient(0deg, #2d3142, #212742);
}

button {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.05em;
}

.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.slide-enter {
  -webkit-transform: translateY(40%);
          transform: translateY(40%);
  opacity: 0;
}

.slide-enter.slide-enter-active {
  opacity: 1;  
  -webkit-transform: translateY(0);  
          transform: translateY(0);
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.slide-exit {
  opacity: 1;
}

.slide-exit.slide-exit-active {
  opacity: 0;
  -webkit-transform: translateX(150%);
          transform: translateX(150%);
  /* transition: all 0.5s ease-in; */
  -webkit-transition: all 0.5s cubic-bezier(0.9,-0.4,0.3,1);
  transition: all 0.5s cubic-bezier(0.9,-0.4,0.3,1);
}
